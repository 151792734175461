.filters {
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: -moz-fit-content;
  height: fit-content;
}
.filters .title {
  align-self: start;
  font-weight: bold;
}
.filters .Date {
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.filters .Date .dateSec {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  flex-wrap: wrap;
}
.filters .Date .dateSec label {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.filters .Date .dateSec label span {
  font-size: 13px;
  font-weight: bold;
}
.filters .Date .dateSec input[type=date] {
  width: 105px;
  border-radius: 10px;
  font-family: "Inter";
  font-size: 12px;
  border: 2px solid #D1D1D1;
  outline: none;
  padding: 10px 8px 10px 8px;
  cursor: pointer;
}
.filters .AscDescOrder {
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.filters .AscDescOrder .AscDescSec {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-self: center;
  flex-wrap: wrap;
  width: -moz-fit-content;
  width: fit-content;
}
.filters .AscDescOrder .AscDescSec .checkSquare {
  color: #008a4a;
  font-size: 25px;
}
.filters .AscDescOrder .AscDescSec #Desc, .filters .AscDescOrder .AscDescSec #Asc {
  border: 1px solid #D1D1D1;
  background-color: #F9F9F9;
  padding: 12px 10px 12px 10px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 8px;
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  cursor: pointer;
}
.filters .AscDescOrder .AscDescSec #Desc .square, .filters .AscDescOrder .AscDescSec #Asc .square {
  height: 20px;
  width: 20px;
  border: 2px solid #D1D1D1;
  border-radius: 6px;
}
.filters .Currency {
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.filters .Currency .title {
  align-self: center;
}
.filters .Currency .currency {
  list-style: none;
  list-style: none;
  line-height: 30px;
}
.filters .Currency .currency li {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  cursor: pointer;
}
.filters .Currency .currency li .square {
  height: 20px;
  width: 20px;
  border: 2px solid #D1D1D1;
  border-radius: 6px;
}
.filters .Currency .currency li .checkSquare {
  color: #008a4a;
  font-size: 25px;
}
.filters .PriceRange {
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.filters .PriceRange .Range {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.filters .PriceRange .Range label {
  display: flex;
  flex-direction: column;
}
.filters .PriceRange .Range label span {
  font-weight: bold;
  font-size: 13px;
}
.filters .PriceRange .Range input {
  border-radius: 12px;
  width: 80px;
  height: 35px;
  border: 2px solid #D1D1D1;
  outline: none;
  background-color: #f0f0f0;
  padding: 0px 0px 0px 8px;
}
.filters .resetBtn {
  padding: 10px 16px 10px 16px;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
  border-radius: 10px;
  border: 1px solid #008a4a;
  outline: none;
  color: white;
  font-family: "Inter";
  background-color: #02a75a;
  font-weight: bold;
  cursor: pointer;
}
.filters .resetBtn:hover {
  animation: resetBtn 0.5s forwards ease-in-out;
}
@keyframes resetBtn {
  to {
    color: #006435;
    background-color: #13fd90;
    border-color: #13fd90;
  }
}/*# sourceMappingURL=FiltersForOrders.css.map */