@import url('https://fonts.googleapis.com/css?family=Exo');
@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css?family=Poppins');

.GeneralLayout {
    .bg {
        //background: linear-gradient(-45deg, #6af4a6, #02ad5d 60%);
        position: absolute;
        height: 100vh;
        width: 30%;
        right: 0px;
        top: 0px;
        z-index: -1;
    }

    header {
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;

        h1 {
            justify-self: center;
            font-family: 'Exo';
            font-weight: 700;

            span {
                color: #0af185;
            }
        }

        nav {
            justify-self: center;
            display: flex;
            column-gap: 24px;

            .linksnav {
                text-decoration: none;
                color: #353535;
                font-family: 'Inter';
            }

            .active {
                font-weight: bold;
            }
        }

        .signupContactsec {
            display: flex;
            column-gap: 10px;
            justify-self: center;

            .links {
                color: white;
                padding: 12px 30px 12px 30px;
                border-radius: 20px;
                border: none;
                background-color: #008a4a;
                font-family: 'Inter';
                font-size: 16px;
                cursor: pointer;
                text-decoration: none;
            }

            .links:hover {
                animation: navBtn 0.5s forwards ease-in-out;
            }

            @keyframes navBtn {
                to {
                    background-color: #13fd90;
                    color: #06864a;
                }
            }
        }

    }

    footer {
        background-color: #F3F5F6;

        .upperPart {
            display: grid;
            grid-template-columns: auto auto auto auto;

            .infoElm {
                justify-self: center;
                width: 250px;
                font-family: 'Inter';

                input {
                    border: 1px solid #7B838F;
                    height: 40px;
                    width: inherit;
                    border-radius: 10px;
                    padding: 0px 0px 0px 10px;
                    outline: none;
                }

                .addStyle {
                    line-height: 26px;
                }

                p {
                    color: #7B838F;
                    font-size: 14px;
                }

                h3 {
                    color: #1b3e6e;
                    font-size: 22px;

                    span {
                        color: #02a75a;
                    }
                }

                .linkList {
                    display: flex;
                    flex-direction: column;
                    row-gap: 15px;

                    .link {
                        color: #7B838F;
                        text-decoration: none;
                        font-size: 14px;
                    }
                }
            }
        }

        .lowerPart {
            display: grid;
            grid-template-columns: auto auto;
            padding: 20px 0px 20px 0px;
            font-family: 'Inter';
            font-size: 14px;
            color: #7B838F;

            .copyright {
                margin-left: 80px;
                justify-self: start;
            }

            div {
                display: flex;
                column-gap: 30px;
                justify-self: end;
                margin-right: 80px;
            }
        }
    }
}

.Home {

    .upperPart {
        display: grid;
        margin-top: 40px;
        grid-template-columns: auto auto;
        padding-bottom: 80px;

        .Short-description {
            width: 456px;
            height: 448px;
            margin-top: 50px;
            font-family: 'Inter';
            justify-self: center;

            span {

                color: #FD8D1E;
            }

            .email {
                display: flex;
                height: 60px;
                width: 300px;
                box-shadow: 0px 2px 5px rgb(156, 156, 156);
                column-gap: 10px;
                border-radius: 10px;

                button {
                    border-radius: 10px;
                    border: none;
                    width: 60px;
                    height: 45px;
                    align-self: center;
                    background-color: #419E6A;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                }

                button:hover {
                    animation: tryit 0.5s forwards ease-in;
                }

                @keyframes tryit {
                    100% {

                        background-color: #13fd90;
                        color: #066337;
                    }
                }

                input {
                    border: none;
                    padding: 0px 0px 0px 16px;
                    background-color: transparent;
                    width: 200px;
                    font-family: 'Inter';
                }

                input:focus {
                    outline: none;
                }
            }
        }

        .BuySell {
            background-color: white;
            width: 356px;
            height: fit-content;
            border-radius: 16px;
            padding-bottom: 30px;
            box-shadow: 1px 1px 10px rgb(162, 162, 162);

            .userChoice {
                position: relative;

                %buttonCommonDesign {
                    cursor: pointer;
                    font-family: 'Inter';
                    position: absolute;
                    padding: 8px 0px 0px 0px;
                    text-align: center;
                    height: 30px;
                    width: 60px;

                }

                .Buy {
                    @extend %buttonCommonDesign;
                    border-bottom-right-radius: 16px;
                    border-top-left-radius: 16px;
                    box-shadow: 2px 2px 5px rgb(132, 132, 132);
                }

                .Sell {
                    @extend %buttonCommonDesign;
                    right: 0px;
                    border-bottom-left-radius: 16px;
                    border-top-right-radius: 16px;
                    box-shadow: -2px 2px 5px rgb(132, 132, 132);
                }
            }

            form {
                margin-top: 50px;
                display: flex;
                flex-direction: column;
                padding-left: 20px;
                padding-right: 20px;
                row-gap: 16px;

                font-family: 'Inter';

                label {
                    font-family: 'Inter';
                    font-size: 12px;
                }

                input {
                    height: 30px;
                    border: 1px solid #bababa;
                    border-radius: 8px;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input:focus {
                    outline: none;
                }

                .errorField {
                    input {
                        border: 1px solid #f33939;
                        color: #f33939;
                    }

                    ::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #f33939;
                        opacity: 1;
                    }

                    span {
                        font-size: 11px;
                        text-align: center;
                        color: #f33939;
                    }
                }

                #asset {
                    height: 30px;
                    border: 1px solid #bababa;
                    border-radius: 8px;
                    font-family: 'Inter';
                    position: relative;
                    display: flex;

                    .selectedVal {
                        align-self: center;
                        display: grid;
                        grid-template-columns: auto auto;
                        align-items: center;
                        column-gap: 6px;
                        padding: 0px 0px 0px 8px;
                        width: 100%;
                        cursor: pointer;

                        span {
                            width: fit-content;
                            display: flex;
                            align-items: center;
                            column-gap: 8px;

                            .logo {
                                width: 16px;
                            }
                        }

                        .arrows {
                            width: 10px;
                            justify-self: flex-end;
                            margin: 0px 8px 0px 0px;
                        }
                    }

                    .list {
                        z-index: 2;
                        top: 32px;
                        width: 100%;
                        position: absolute;
                        background-color: white;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        box-shadow: 0px 5px 10px rgb(167, 167, 167);

                        div {
                            display: flex;
                            align-items: center;
                            column-gap: 6px;
                            padding: 5px 0px 5px 10px;
                            cursor: pointer;

                            img {
                                width: 15px;
                            }
                        }

                        div:hover {
                            background-color: #dcdcdc;
                        }
                    }
                }

                select:focus {
                    outline: none;
                }

                .formSec {
                    display: flex;
                    flex-direction: column;
                }

                #email {
                    padding: 0px 0px 0px 16px;
                }

                .ExchangeValue {
                    display: grid;
                    grid-template-columns: auto auto auto;

                    .crypto {
                        display: flex;
                        flex-direction: column;

                        .Cryptovalue {
                            width: 80px;
                            height: 23px;
                            padding: 7px 0px 0px 10px;
                            border: 1px solid #bababa;
                            font-size: 14px;
                            border-radius: 10px;
                            overflow-x: auto;
                            overflow-y: hidden;

                        }

                        .Cryptovalue::-webkit-scrollbar {
                            height: 6px;
                            border-radius: inherit;
                        }

                        div::-webkit-scrollbar-thumb {
                            border-radius: inherit;
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                            background-color: #555;
                        }

                    }

                    .livePrice {
                        align-self: center;
                        display: flex;
                        flex-direction: column;
                        row-gap: 8px;

                        .currencyPair {
                            font-size: 11px;
                            display: flex;
                            align-items: center;
                            column-gap: 6px;

                            img {
                                width: 20px;
                            }
                        }

                        .currencyPrice {
                            align-self: center;
                            padding: 0px 10px 0px 0px;
                        }
                    }

                    .FIAT {
                        justify-self: end;
                        display: flex;
                        flex-direction: column;

                        input {
                            width: 70px;
                            height: 25px;
                            padding: 3px 0px 0px 10px;
                        }
                    }
                }

                button {
                    width: 152px;
                    height: 38px;
                    font-size: 16px;
                    border-radius: 8px;
                    border: none;
                    cursor: pointer;
                    align-self: center;
                }

                button:hover {
                    animation: confirmBuySell 0.4s forwards ease-in;
                }

                %commonLoadingBtn {
                    display: inline-block;
                    width: fit-content;
                    height: fit-content;
                    align-self: center;
                }

                %commonLoadingBtnAfter {
                    content: " ";
                    display: block;
                    width: 32px;
                    height: 32px;
                    margin: 8px;
                    border-radius: 50%;
                    animation: lds-dual-ring 1.2s linear infinite;
                }

                .lds-dual-ring-green {
                    @extend %commonLoadingBtn;
                }

                .lds-dual-ring-green:after {
                    @extend %commonLoadingBtnAfter;
                    border: 6px solid #19bc2f;
                    border-color: #19bc2f transparent #19bc2f transparent;
                }

                .lds-dual-ring-red {
                    @extend %commonLoadingBtn;
                }

                .lds-dual-ring-red:after {
                    @extend %commonLoadingBtnAfter;
                    border: 6px solid #ff0000;
                    border-color: #ff0000 transparent #ff0000 transparent;
                }

                @keyframes lds-dual-ring {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }

                @keyframes confirmBuySell {
                    100% {
                        box-shadow: 0px 0px 8px rgb(0, 0, 0);
                    }
                }

                .wrapper {
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                %checkmark__circle {
                    stroke-dasharray: 166;
                    stroke-dashoffset: 166;
                    stroke-width: 2;
                    stroke-miterlimit: 10;
                    fill: none;
                    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
                }

                .checkmark__circle_green {
                    @extend %checkmark__circle;
                    stroke: #19bc2f;
                }

                .checkmark__circle_red {
                    @extend %checkmark__circle;
                    stroke: #ff0000;
                }

                %checkmark {
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    display: block;
                    stroke-width: 2;
                    stroke: #fff;
                    stroke-miterlimit: 10;
                }

                .checkmark-green {
                    @extend %checkmark;
                    box-shadow: inset 0px 0px 0px #19bc2f;
                    animation: fillgreen .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
                }

                .checkmark-red {
                    @extend %checkmark;
                    box-shadow: inset 0px 0px 0px #ff0000;
                    animation: fillred .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
                }

                .checkmark__check {
                    transform-origin: 50% 50%;
                    stroke-dasharray: 48;
                    stroke-dashoffset: 48;
                    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
                }

                @keyframes stroke {
                    100% {
                        stroke-dashoffset: 0
                    }
                }

                @keyframes scale {

                    0%,
                    100% {
                        transform: none
                    }

                    50% {
                        transform: scale3d(1.1, 1.1, 1)
                    }
                }

                @keyframes fillgreen {
                    100% {
                        box-shadow: inset 0px 0px 0px 30px #19bc2f
                    }
                }

                @keyframes fillred {
                    100% {
                        box-shadow: inset 0px 0px 0px 30px #ff0000
                    }
                }

                .Address {
                    input {
                        background-color: #F2F3F5;
                        padding: 0px 0px 0px 16px;
                    }

                    input:focus {
                        outline: none;
                    }
                }
            }
        }
    }


    .LivePriceDisplay {
        width: fit-content;
        display: flex;
        //grid-template-columns: auto auto auto;
        margin: 0px auto 0px auto;
        column-gap: 30px;
        padding: 0px 0px 60px 0px;

        .crypto {
            justify-self: center;
            display: flex;
            width: 400px;
            flex-direction: row;
            column-gap: 20px;
            align-items: center;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            box-shadow: 1px 1px 10px rgb(136, 136, 136, 0.8);
            padding: 0px 15px 0px 0px;

            .cryptoInfo {
                display: flex;
                flex-direction: column;
                row-gap: 6px;

                .Title {
                    font-family: 'Inter';
                    font-weight: bold;
                }

                .value {
                    font-size: 16px;
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    column-gap: 6px;

                    i {
                        font-size: 12px;
                        border-radius: 50%;
                        padding: 1px 2px 0px 2px;
                        box-shadow: 1px 1px 2px rgb(33, 30, 30);
                    }
                }
            }

            img {
                width: 60px;
            }
        }
    }
}

.loginSignup {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    margin-top: 50px;
    margin-bottom: 100px;

    .loginSignupForm {
        position: relative;
        justify-self: center;
        background-color: white;
        width: 800px;
        height: 420px;
        border-radius: 32px;
        display: flex;
        box-shadow: 1px 1px 10px rgb(87, 87, 87, 0.5);

        .welcomingMessage {
            position: absolute;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            height: 100%;
            width: 400px;
            left: 0;
            padding: 0px 40px 0px 40px;
            background: linear-gradient(-45deg, #2dc870, #02ad5d 30%);
            border-bottom-left-radius: inherit;
            border-top-left-radius: inherit;

            .bigLegend {
                font-family: sans-serif;
                align-self: center;
                font-weight: bold;

                font-size: 32px;
                margin-top: 40%;
                color: white;
                animation: welcomingText 0.8s forwards ease-in;
            }

            .shortGuide {
                align-self: center;
                font-family: 'Inter';
                width: 300px;
                text-align: center;
                color: white;
                animation: welcomingText 1s forwards ease-in-out;
            }

            @keyframes welcomingText {
                0% {
                    transform: translateY(-100%);
                    opacity: 0;
                }

                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            .welcomeBtn {
                align-self: center;
                padding: 10px 32px 12px 32px;
                border: 1px solid white;
                border-radius: 16px;
                color: white;
                font-family: 'Inter';
                cursor: pointer;
                animation: welcomingBtn 1.2s ease-in-out;
            }

            @keyframes welcomingBtn {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }

            .welcomeBtn:hover {
                animation: alternativeLoginSignup 0.5s forwards ease-in;
            }

            @keyframes alternativeLoginSignup {
                100% {
                    background-color: #13fd90;
                    color: #066337;
                }
            }
        }

        .codeOfVerifcationSec {
            width: 280px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0px 0px 0px 25px;

            h2 {
                text-align: center;
                font-family: 'Inter';
                font-weight: bold;
            }

            p {
                font-family: 'Inter';
                text-align: center;
            }

            span {
                font-family: 'Inter';
                padding: 0px 0px 10px 0px;
            }

            .error {
                color: red;
            }

            .options {
                font-family: 'Inter';
                margin-top: 20px;

                .option {
                    color: #008a4a;
                    cursor: pointer;
                }
            }

        }

        .signup {
            position: absolute;
            top: 16%;
            width: 250px;
            right: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 16px;
            animation: signup 1s forwards ease-in-out;
            transition: all 2s;

            .lastSetRegi {
                text-align: center;
                font-family: 'Inter';
                color: #484848;
                font-size: 13px;
            }

            .errors {
                input {
                    border: 1px solid red;
                }

                ::placeholder {
                    color: #f33939 !important;
                    opacity: 1;
                }
            }

            h1 {

                font-family: sans-serif;
            }

            padding: 0px 40px 0px 40px;
        }

        @keyframes signup {
            from {
                transform: translateY(40%);
                opacity: 0;
            }

            to {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .login {
            position: absolute;
            top: 14%;
            width: 250px;
            left: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 16px;
            animation: login 1s forwards ease-in-out;
            transition: all 2s;

            .lastSetRegi {
                text-align: center;
                font-family: 'Inter';
                color: #484848;
                font-size: 13px;
            }

            .errors {
                input {
                    border: 1px solid red;
                }

                ::placeholder {
                    color: #f33939 !important;
                    opacity: 1;
                }
            }

            h1 {

                font-family: sans-serif;
            }

            padding: 0px 40px 0px 40px;
        }

        @keyframes login {
            from {
                transform: translateY(40%);
                opacity: 0;
            }

            to {
                transform: translateY(0);
                opacity: 1;
            }
        }

        input {
            width: 200px;
            height: 45px;
            padding: 0px 0px 0px 20px;
            background-color: rgb(229, 229, 229);
            outline: none;
            color: black;
            border: none;
            border-radius: 10px;

            ::-webkit-input-placeholder {
                color: rgb(88, 88, 88);
                opacity: 1;
                font-size: 14px;
                font-family: 'Inter';
            }
        }

        input:focus {
            border: 2px solid rgb(105, 255, 130);
            border-radius: 10px;
        }




        button {
            margin-top: 8px;
            width: 150px;
            height: 40px;
            border-radius: 32px;
            outline: none;
            border: none;
            background-color: #02ad5d;
            font-family: 'Inter';
            font-size: 15px;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
        }

        button:hover {
            animation: contactus 0.5s forwards ease-in;
        }

        @keyframes contactus {
            100% {
                background-color: #02ad5d;
                color: #FFFFFF;
            }
        }

    }

    .signupORlogin {
        width: 400px;
    }
}

.ForgotPassword {
    width: 350px;
    text-align: center;
    display: grid;
    grid-template-rows: auto;
    justify-items: center;
    box-shadow: 1px 1px 10px rgb(104, 104, 104, 0.5);
    padding: 40px 40px 40px 40px;
    border-radius: 10px;
    font-family: 'Inter';
    margin: 100px auto 0px auto;
    background-color: white;

    margin-bottom: 100px;

    img {
        width: 60px;
    }

    h1 {
        margin-bottom: -5px;
        font-family: Arial, Helvetica, sans-serif;
    }

    p {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.5);
    }

    .emailSubm {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;

        %commonFieldDimension {
            height: 40px;
            border-radius: 6px
        }

        .err {
            color: red;
            border: 1px solid red;
        }

        input {
            @extend %commonFieldDimension;
            border: 1px solid #bababa;
            padding: 0px 0px 0px 16px;
        }

        input:focus {
            outline: none;
        }

        button {
            @extend %commonFieldDimension;
            background-color: #19bc2f;
            border: none;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            font-weight: bold;
            color: white;
            cursor: pointer;
        }


    }

    .passwordInput {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        %commonFieldDimension {
            height: 40px;
            border-radius: 6px
        }

        width: 100%;

        button {
            @extend %commonFieldDimension;
            background-color: #19bc2f;
            border: none;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            font-weight: bold;
            color: white;
            cursor: pointer;
        }

        .inputFieldsCode {
            align-self: center;
            display: flex;
            column-gap: 10px;

            .inputField {
                width: 30px;
                height: 50px;
                padding: 5px 0px 0px 0px;
                font-size: 20px;
                text-align: center;
                border: 2px solid rgb(202, 202, 202);
                border-radius: 4px;
            }

            input[type=number]::-webkit-outer-spin-button,
            input[type=number]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            ::placeholder {
                text-align: center;
                font-size: 20px;
            }
        }
    }

    .error {

        input {
            border: 1px solid red;
        }

        ::placeholder {
            color: red;
        }
    }

    .backToLogin {
        display: block;
        margin-top: 30px;
        text-decoration: none;
        display: flex;
        column-gap: 6px;
        align-items: center;
        color: rgb(86, 86, 86);

        span {
            font-size: 14px;
        }

        i {
            font-size: 12px;
        }
    }
}

.AboutUs {
    display: flex;
    font-family: 'Inter';

    h1 {
        text-align: center;
    }

    p {
        font-size: 14px;
        text-align: center;
    }

    .shortCatch {
        text-align: center
    }

    .Squares {
        position: relative;
        width: 1200px;
        height: 500px;

        .SquaresWithInfo {
            background-color: white;
            position: absolute;
            padding: 10px 30px 10px 30px;
            border-radius: 10px;
            max-width: 300px;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 1px 1px 10px rgb(51, 50, 50, 0.5);

            img {
                width: 200px;
            }

            text-align: center;

            p {
                font-size: 14px;
                color: rgb(0, 0, 0);
            }
        }

        .box0 {
            left: 100px;
        }

        .box1 {
            top: 200px;
        }

        .box2 {
            left: 20px;
        }
    }
}

.HowTo {

    .mainTitle {
        text-align: center;
        font-family: 'Inter';
    }

    .middleSection {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: fit-content;
        margin: 50px auto 50px auto;

        .exchangeShortdescript {
            display: flex;
            flex-wrap: wrap;
            column-gap: 16px;
            row-gap: 20px;

            .boxInfo {
                padding: 10px 16px 0px 16px;
                width: 250px;
                box-shadow: 1px 1px 10px rgb(57, 57, 57, 0.5);
                border-radius: 26px;
                font-family: 'Inter';

                .upperPart {
                    display: flex;
                    column-gap: 10px;
                }

                .lowerPart {
                    display: flex;

                    p {
                        font-size: 13px;
                    }

                    i {
                        display: none;
                        font-size: 25px;
                    }
                }

                .tutorial {
                    display: none;
                }

                cursor:pointer;
            }

            .boxInfo:hover {
                i {
                    display: block;
                    animation: arrowAnimation 0.5s forwards ease-out;
                }
            }

            @keyframes arrowAnimation {
                from {
                    transform: translateX(-100%);
                    opacity: 0
                }

                to {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }

        .DepoWithtutorial {
            width: 500px;
            height: fit-content;
            //margin: 50px auto 50px auto;
            animation: depowithtutorial 0.5s forwards ease-in-out;
            font-family: 'Inter';
            font-size: 13px;
            box-shadow: 1px 1px 10px rgb(39, 37, 37, 0.5);
            border-radius: 16px;
            padding: 10px 10px 20px 10px;

            .upperPart {
                display: flex;
                column-gap: 20px;
                align-items: center;
            }

            .lowerpart {
                padding: 0px 0px 0px 90px;
            }
        }

        @keyframes depowithtutorial {
            from {
                opacity: 0;
                transform: translateY(-10%);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

}

.personalArea {
    font-family: 'Inter';
    display: grid;
    grid-template-columns: 16% auto 20%;

    .MainPart {
        height: 85vh;
        overflow-y: auto;
        border-radius: 10px;

        .Orders {
            padding: 20px 0px 50px 30px;
            background-color: transparent;
            display: flex;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 30px;
            height: fit-content;
        }

        .CreateOrder {
            margin-top: 80px;
        }

    }

    .loader {
        width: 100vw;
        height: 90vh;
        display: flex;
        align-items: center;

        .lds-dual-ring-green {
            margin: 0px auto 0px auto;
        }
    }
}

.SignupSuccess {
    height: 80vh;
    display: flex;
    align-items: center;
    width: 100%;

    .formSection {
        padding: 24px 24px 30px 24px;
        font-family: 'Inter';
        min-height: 400px;
        margin: 0px auto 0px auto;
        width: fit-content;
        box-shadow: 1px 1px 10px rgb(104, 104, 104);
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        .upperPart {
            margin: 0px auto 0px auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                font-size: 24px;
                opacity: 0.5;
            }

            img {
                width: 40px;
            }

            padding: 0px 16px 24px 16px;
            border-bottom: 1px solid rgb(185, 185, 185);
        }



        .middlePart {
            margin: 0px auto 0px auto;
            width: fit-content;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            align-items: center;

            p {
                align-self: center;
                max-width: 300px;
            }

            .codeResent {
                color: #02ad5d;
            }

            .errorCode {
                color: red;
            }

        }

        .bottomPart {
            display: flex;
            column-gap: 50px;
            align-self: center;
            margin: 20px 0px 0px 0px;

            .link {
                text-decoration: none;
                color: #008a4a;
                cursor: pointer;
            }
        }
    }

}

.Admin {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    height: 70vh;

    .LeftSec {
        display: flex;
        flex-direction: column;
        row-gap: 34px;
        padding: 0px 0px 0px 24px;

        .option {
            font-family: 'Poppins';
            display: flex;
            align-items: center;
            column-gap: 17px;
            cursor: pointer;

            img {
                width: 20px;
            }
        }

    }

    .RightSec {
        background-color: #F8F8F8;
        padding: 20px 20px 20px 50px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        height: 89vh;

        .Userlist {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            background-color: white;
            font-family: 'Inter', sans-serif;
            width: fit-content;

            th,
            td {
                padding: 10px;
                border-bottom: 1px solid #d2d2d2;
                color: rgb(91, 91, 91);
            }

            thead {

                th,
                td {
                    padding: 20px 10px 20px 10px;
                    color: black;
                }
            }


            .user {
                cursor: pointer;
                
                .orderId {
                    color: #0B63F8;
                }

                .creationTime {
                    display: flex;
                    flex-direction: column;
                    row-gap: 6px;
                }

                transition: all 0.5s;
            }

            .user:hover {
                background-color: #dfdfdf;
            }

            .OrderOption {
                display: flex;
                column-gap: 10px;
                padding: 0px 0px 10px 20px;
                div {
                    padding: 10px;
                    box-shadow: 1px 1px 10px rgb(123, 123, 123);
                    cursor: pointer;
                    border-radius: 8px;
                }
                .orderCancelled{
                    background-color: #ed0000;
                    color: white;
                }
                .orderCompleted {
                    background-color: #029e55;
                    color: white;
                }
            }

        }

        .SearchFields {
            display: flex;
            flex-direction: row;
            column-gap: 40px;

            label {
                display: flex;
                flex-direction: column;
                row-gap: 16px;
                font-family: 'Poppins';

                input {
                    width: 246px;
                    height: 50px;
                    border: 2px solid rgb(91, 91, 91, 0.5);
                    border-radius: 4px;
                    outline: none;
                    font-size: 16px;
                    padding: 0px 10px 0px 10px;
                }

                input[type="date"] {
                    font-family: 'Poppins';
                    color: rgb(22, 22, 22, 0.6);
                    font-size: 16px;
                }
            }
        }

        .DataWidget {
            display: flex;
            flex-direction: row;
            column-gap: 20px;

            .widget {
                height: 91px;
                width: 265px;
                background-color: white;
                border-radius: 10px;
                box-shadow: 1px 1px 10px rgb(152, 152, 152);
                display: flex;
                align-items: center;

                img {
                    justify-self: end;
                    width: 35px;
                    margin: 0px 17.5px 0px 27px;
                }

                .TextInfo {
                    display: flex;
                    flex-direction: column;
                    font-family: 'Poppins';
                    margin: 0px auto 0px 0px;

                    .sum {
                        font-size: 22px;
                        font-weight: bold;
                    }
                }
            }
        }

        .MessageSec {
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .Messages {
                display: grid;
                column-gap: 20px;
                grid-template-columns: auto 50%;
                font-family: 'Inter';

                .messageUser {
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;

                    .user {
                        box-shadow: 1px 1px 10px rgb(44, 44, 44);
                        display: grid;
                        grid-template-columns: auto auto;
                        row-gap: 10px;
                        padding: 10px 20px 10px 20px;
                        border-radius: 10px;
                        cursor: pointer;

                        .dateOfCreation {
                            justify-self: end;
                        }
                    }

                    .active {
                        box-shadow: none;
                    }
                }

                .chatSec {
                    max-height: 60vh;
                    padding: 10px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                    background-color: white;
                    box-shadow: 1px 1px 10px rgb(44, 44, 44);


                    .userId {
                        display: grid;
                        grid-template-columns: auto auto auto auto;
                        align-items: center;
                        column-gap: 10px;

                        .icon {
                            border: 1px solid #d2d2d2;

                            width: 34px;
                            text-align: center;
                            padding: 8px 0px 8px 0px;
                            border-radius: 50%;
                        }

                        input {
                            font-family: 'Poppins';
                            width: 200px;
                            height: 30px;
                            border: 2px solid rgb(91, 91, 91, 0.5);
                            border-radius: 4px;
                            outline: none;
                            font-size: 16px;
                            padding: 0px 10px 0px 10px;
                            justify-self: end;
                        }

                        input[type="date"] {
                            font-family: 'Poppins';
                            color: rgb(22, 22, 22, 0.6);
                            font-size: 16px;
                        }

                        button {
                            height: 34px;
                            border-radius: 10px;
                            outline: none;
                            border: none;
                            cursor: pointer;
                            box-shadow: 1px 1px 5px black;

                        }

                        button:focus {
                            animation: shadow 0.3s ease-in-out;
                            /* Add animation */
                        }

                        @keyframes shadow {
                            0% {
                                box-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
                                /* Initial box shadow */
                            }

                            50% {
                                box-shadow: 0px 0px 0px rgba(0, 0, 0, 1), 0px 4px 8px rgba(0, 0, 0, 0.2);
                                /* Remove box shadow and add shadow below */
                            }

                            100% {
                                box-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
                                /* Final box shadow */
                            }
                        }

                    }

                    .chatmessages {
                        background-color: #dfdfdf;
                        border-radius: 10px;
                        min-height: 200px;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;

                        .admin {
                            display: flex;
                            width: fit-content;
                            column-gap: 20px;

                            .icon {
                                background-color: white;
                                margin-top: 20px;
                                margin-left: 20px;
                                max-width: 34px;
                                padding: 5px;
                                border-radius: 50%;
                                height: fit-content;
                            }

                            p {
                                background-color: white;
                                max-width: 300px;
                                padding: 10px;
                                border-radius: 10px;
                            }

                        }

                        .user {
                            display: flex;
                            width: fit-content;
                            column-gap: 20px;
                            align-self: flex-end;

                            .icon {
                                display: flex;
                                align-items: center;
                                margin-top: 20px;
                                margin-right: 20px;
                                background-color: white;
                                height: 30px;
                                width: 30px;
                                border-radius: 50%;
                                padding: 5px;

                                i {
                                    margin: 0px auto 0px auto;
                                }
                            }

                            p {
                                background-color: white;
                                max-width: 300px;
                                padding: 10px;
                                border-radius: 10px;
                            }

                        }

                    }

                    .sendingMessage {
                        width: 100%;
                        display: grid;
                        grid-template-columns: 90% auto;
                        column-gap: 10px;

                        %commonDesign {
                            border-radius: 10px;
                            outline: none;
                            border: 1px solid #d2d2d2
                        }

                        input {
                            @extend %commonDesign;
                        }

                        button {
                            width: 50px;
                            height: 40px;
                            background-color: #029e55;

                            i {
                                color: rgb(255, 255, 255)
                            }

                            @extend %commonDesign;
                        }
                    }
                }


            }
        }


        .SearchMessage {
            display: flex;
            flex-direction: row;
            column-gap: 40px;

            label {
                display: flex;
                flex-direction: column;
                row-gap: 16px;
                font-family: 'Poppins';

                input {
                    width: 246px;
                    height: 50px;
                    border: 2px solid rgb(91, 91, 91, 0.5);
                    border-radius: 4px;
                    outline: none;
                    font-size: 16px;
                    padding: 0px 10px 0px 10px;
                }

                input[type="date"] {
                    font-family: 'Poppins';
                    color: rgb(22, 22, 22, 0.6);
                    font-size: 16px;
                }
            }
        }
    }
}

.adminLoginSec {

    .codeVerify {
        margin: 100px auto 0px auto;
        height: 400px;
        box-shadow: 1px 1px 10px rgb(15, 15, 15, 0.5);
        width: 400px;
        font-family: 'Inter';
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        padding: 20px 10px 0px 10px;
        border-radius: 10px;

        .title {
            text-align: center;
        }

        img {
            width: 100px;
        }

        .resendCode {
            color: #02ad5d;
            cursor: pointer;
        }
    }

    .adminLogin {
        height: 323px;
        width: 463px;
        border-radius: 10px;
        box-shadow: 1px 1px 10px rgb(83, 83, 83);
        margin: 100px auto 100px auto;
        display: flex;
        flex-direction: column;
        row-gap: 28px;
        padding: 44px 88px 44px 88px;

        .title {
            align-self: center;
            font-family: 'Inter';
            color: #666666;
        }

        label {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            font-family: 'Inter';
            color: #666666;

            .error {
                border: 1px solid red !important;
            }

            input {
                height: 38px;
                border-radius: 8px;
                outline: none;
                border: none;
                background-color: #dfdfdf;
                padding-left: 20px;
            }
        }

        button {
            background-color: #016e3b;
            border: none;
            cursor: pointer;
            padding: 13px;
            border-radius: 10px;
            color: white;
            font-family: 'Inter';
            font-size: 16px;
        }

        .forgotPass {
            align-self: flex-end;
            font-family: 'Inter';
            color: #999999;
        }
    }
}


.AdminLayout {
    header {
        height: fit-content;
        width: 100%;
        padding: 2px 0px 8px 0px;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 450px;

        h1 {
            font-family: 'Inter';
            justify-self: center;

            span {
                color: #0af185;
            }

        }

        .links {
            display: flex;
            column-gap: 10px;
            justify-self: center;

            .link {
                display: block;
                width: fit-content;
                height: fit-content;
                text-decoration: none;
                color: white;
                background-color: #008a4a;
                padding: 10px;
                border-radius: 10px;
                font-family: 'Inter';
                align-self: center;
            }
        }

    }
}