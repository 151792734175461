@import url("https://fonts.googleapis.com/css?family=Exo");
@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://fonts.googleapis.com/css?family=Poppins");
.GeneralLayout .bg {
  position: absolute;
  height: 100vh;
  width: 30%;
  right: 0px;
  top: 0px;
  z-index: -1;
}
.GeneralLayout header {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
}
.GeneralLayout header h1 {
  justify-self: center;
  font-family: "Exo";
  font-weight: 700;
}
.GeneralLayout header h1 span {
  color: #0af185;
}
.GeneralLayout header nav {
  justify-self: center;
  display: flex;
  -moz-column-gap: 24px;
       column-gap: 24px;
}
.GeneralLayout header nav .linksnav {
  text-decoration: none;
  color: #353535;
  font-family: "Inter";
}
.GeneralLayout header nav .active {
  font-weight: bold;
}
.GeneralLayout header .signupContactsec {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  justify-self: center;
}
.GeneralLayout header .signupContactsec .links {
  color: white;
  padding: 12px 30px 12px 30px;
  border-radius: 20px;
  border: none;
  background-color: #008a4a;
  font-family: "Inter";
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}
.GeneralLayout header .signupContactsec .links:hover {
  animation: navBtn 0.5s forwards ease-in-out;
}
@keyframes navBtn {
  to {
    background-color: #13fd90;
    color: #06864a;
  }
}
.GeneralLayout footer {
  background-color: #F3F5F6;
}
.GeneralLayout footer .upperPart {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.GeneralLayout footer .upperPart .infoElm {
  justify-self: center;
  width: 250px;
  font-family: "Inter";
}
.GeneralLayout footer .upperPart .infoElm input {
  border: 1px solid #7B838F;
  height: 40px;
  width: inherit;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
  outline: none;
}
.GeneralLayout footer .upperPart .infoElm .addStyle {
  line-height: 26px;
}
.GeneralLayout footer .upperPart .infoElm p {
  color: #7B838F;
  font-size: 14px;
}
.GeneralLayout footer .upperPart .infoElm h3 {
  color: #1b3e6e;
  font-size: 22px;
}
.GeneralLayout footer .upperPart .infoElm h3 span {
  color: #02a75a;
}
.GeneralLayout footer .upperPart .infoElm .linkList {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.GeneralLayout footer .upperPart .infoElm .linkList .link {
  color: #7B838F;
  text-decoration: none;
  font-size: 14px;
}
.GeneralLayout footer .lowerPart {
  display: grid;
  grid-template-columns: auto auto;
  padding: 20px 0px 20px 0px;
  font-family: "Inter";
  font-size: 14px;
  color: #7B838F;
}
.GeneralLayout footer .lowerPart .copyright {
  margin-left: 80px;
  justify-self: start;
}
.GeneralLayout footer .lowerPart div {
  display: flex;
  -moz-column-gap: 30px;
       column-gap: 30px;
  justify-self: end;
  margin-right: 80px;
}

.Home .upperPart {
  display: grid;
  margin-top: 40px;
  grid-template-columns: auto auto;
  padding-bottom: 80px;
}
.Home .upperPart .Short-description {
  width: 456px;
  height: 448px;
  margin-top: 50px;
  font-family: "Inter";
  justify-self: center;
}
.Home .upperPart .Short-description span {
  color: #FD8D1E;
}
.Home .upperPart .Short-description .email {
  display: flex;
  height: 60px;
  width: 300px;
  box-shadow: 0px 2px 5px rgb(156, 156, 156);
  -moz-column-gap: 10px;
       column-gap: 10px;
  border-radius: 10px;
}
.Home .upperPart .Short-description .email button {
  border-radius: 10px;
  border: none;
  width: 60px;
  height: 45px;
  align-self: center;
  background-color: #419E6A;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.Home .upperPart .Short-description .email button:hover {
  animation: tryit 0.5s forwards ease-in;
}
@keyframes tryit {
  100% {
    background-color: #13fd90;
    color: #066337;
  }
}
.Home .upperPart .Short-description .email input {
  border: none;
  padding: 0px 0px 0px 16px;
  background-color: transparent;
  width: 200px;
  font-family: "Inter";
}
.Home .upperPart .Short-description .email input:focus {
  outline: none;
}
.Home .upperPart .BuySell {
  background-color: white;
  width: 356px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 16px;
  padding-bottom: 30px;
  box-shadow: 1px 1px 10px rgb(162, 162, 162);
}
.Home .upperPart .BuySell .userChoice {
  position: relative;
}
.Home .upperPart .BuySell .userChoice .Sell, .Home .upperPart .BuySell .userChoice .Buy {
  cursor: pointer;
  font-family: "Inter";
  position: absolute;
  padding: 8px 0px 0px 0px;
  text-align: center;
  height: 30px;
  width: 60px;
}
.Home .upperPart .BuySell .userChoice .Buy {
  border-bottom-right-radius: 16px;
  border-top-left-radius: 16px;
  box-shadow: 2px 2px 5px rgb(132, 132, 132);
}
.Home .upperPart .BuySell .userChoice .Sell {
  right: 0px;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: -2px 2px 5px rgb(132, 132, 132);
}
.Home .upperPart .BuySell form {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  row-gap: 16px;
  font-family: "Inter";
}
.Home .upperPart .BuySell form label {
  font-family: "Inter";
  font-size: 12px;
}
.Home .upperPart .BuySell form input {
  height: 30px;
  border: 1px solid #bababa;
  border-radius: 8px;
}
.Home .upperPart .BuySell form input::-webkit-outer-spin-button,
.Home .upperPart .BuySell form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Home .upperPart .BuySell form input:focus {
  outline: none;
}
.Home .upperPart .BuySell form .errorField input {
  border: 1px solid #f33939;
  color: #f33939;
}
.Home .upperPart .BuySell form .errorField ::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #f33939;
  opacity: 1;
}
.Home .upperPart .BuySell form .errorField ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #f33939;
  opacity: 1;
}
.Home .upperPart .BuySell form .errorField span {
  font-size: 11px;
  text-align: center;
  color: #f33939;
}
.Home .upperPart .BuySell form #asset {
  height: 30px;
  border: 1px solid #bababa;
  border-radius: 8px;
  font-family: "Inter";
  position: relative;
  display: flex;
}
.Home .upperPart .BuySell form #asset .selectedVal {
  align-self: center;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
  padding: 0px 0px 0px 8px;
  width: 100%;
  cursor: pointer;
}
.Home .upperPart .BuySell form #asset .selectedVal span {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.Home .upperPart .BuySell form #asset .selectedVal span .logo {
  width: 16px;
}
.Home .upperPart .BuySell form #asset .selectedVal .arrows {
  width: 10px;
  justify-self: flex-end;
  margin: 0px 8px 0px 0px;
}
.Home .upperPart .BuySell form #asset .list {
  z-index: 2;
  top: 32px;
  width: 100%;
  position: absolute;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 5px 10px rgb(167, 167, 167);
}
.Home .upperPart .BuySell form #asset .list div {
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
  padding: 5px 0px 5px 10px;
  cursor: pointer;
}
.Home .upperPart .BuySell form #asset .list div img {
  width: 15px;
}
.Home .upperPart .BuySell form #asset .list div:hover {
  background-color: #dcdcdc;
}
.Home .upperPart .BuySell form select:focus {
  outline: none;
}
.Home .upperPart .BuySell form .formSec {
  display: flex;
  flex-direction: column;
}
.Home .upperPart .BuySell form #email {
  padding: 0px 0px 0px 16px;
}
.Home .upperPart .BuySell form .ExchangeValue {
  display: grid;
  grid-template-columns: auto auto auto;
}
.Home .upperPart .BuySell form .ExchangeValue .crypto {
  display: flex;
  flex-direction: column;
}
.Home .upperPart .BuySell form .ExchangeValue .crypto .Cryptovalue {
  width: 80px;
  height: 23px;
  padding: 7px 0px 0px 10px;
  border: 1px solid #bababa;
  font-size: 14px;
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
.Home .upperPart .BuySell form .ExchangeValue .crypto .Cryptovalue::-webkit-scrollbar {
  height: 6px;
  border-radius: inherit;
}
.Home .upperPart .BuySell form .ExchangeValue .crypto div::-webkit-scrollbar-thumb {
  border-radius: inherit;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.Home .upperPart .BuySell form .ExchangeValue .livePrice {
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.Home .upperPart .BuySell form .ExchangeValue .livePrice .currencyPair {
  font-size: 11px;
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
}
.Home .upperPart .BuySell form .ExchangeValue .livePrice .currencyPair img {
  width: 20px;
}
.Home .upperPart .BuySell form .ExchangeValue .livePrice .currencyPrice {
  align-self: center;
  padding: 0px 10px 0px 0px;
}
.Home .upperPart .BuySell form .ExchangeValue .FIAT {
  justify-self: end;
  display: flex;
  flex-direction: column;
}
.Home .upperPart .BuySell form .ExchangeValue .FIAT input {
  width: 70px;
  height: 25px;
  padding: 3px 0px 0px 10px;
}
.Home .upperPart .BuySell form button {
  width: 152px;
  height: 38px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  align-self: center;
}
.Home .upperPart .BuySell form button:hover {
  animation: confirmBuySell 0.4s forwards ease-in;
}
.Home .upperPart .BuySell form .lds-dual-ring-red, .Home .upperPart .BuySell form .lds-dual-ring-green {
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}
.Home .upperPart .BuySell form .lds-dual-ring-red:after, .Home .upperPart .BuySell form .lds-dual-ring-green:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
}
.Home .upperPart .BuySell form .lds-dual-ring-green:after {
  border: 6px solid #19bc2f;
  border-color: #19bc2f transparent #19bc2f transparent;
}
.Home .upperPart .BuySell form .lds-dual-ring-red:after {
  border: 6px solid #ff0000;
  border-color: #ff0000 transparent #ff0000 transparent;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes confirmBuySell {
  100% {
    box-shadow: 0px 0px 8px rgb(0, 0, 0);
  }
}
.Home .upperPart .BuySell form .wrapper {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Home .upperPart .BuySell form .checkmark__circle_red, .Home .upperPart .BuySell form .checkmark__circle_green {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.Home .upperPart .BuySell form .checkmark__circle_green {
  stroke: #19bc2f;
}
.Home .upperPart .BuySell form .checkmark__circle_red {
  stroke: #ff0000;
}
.Home .upperPart .BuySell form .checkmark-red, .Home .upperPart .BuySell form .checkmark-green {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
}
.Home .upperPart .BuySell form .checkmark-green {
  box-shadow: inset 0px 0px 0px #19bc2f;
  animation: fillgreen 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.Home .upperPart .BuySell form .checkmark-red {
  box-shadow: inset 0px 0px 0px #ff0000;
  animation: fillred 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.Home .upperPart .BuySell form .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fillgreen {
  100% {
    box-shadow: inset 0px 0px 0px 30px #19bc2f;
  }
}
@keyframes fillred {
  100% {
    box-shadow: inset 0px 0px 0px 30px #ff0000;
  }
}
.Home .upperPart .BuySell form .Address input {
  background-color: #F2F3F5;
  padding: 0px 0px 0px 16px;
}
.Home .upperPart .BuySell form .Address input:focus {
  outline: none;
}
.Home .LivePriceDisplay {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  margin: 0px auto 0px auto;
  -moz-column-gap: 30px;
       column-gap: 30px;
  padding: 0px 0px 60px 0px;
}
.Home .LivePriceDisplay .crypto {
  justify-self: center;
  display: flex;
  width: 400px;
  flex-direction: row;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 1px 1px 10px rgba(136, 136, 136, 0.8);
  padding: 0px 15px 0px 0px;
}
.Home .LivePriceDisplay .crypto .cryptoInfo {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.Home .LivePriceDisplay .crypto .cryptoInfo .Title {
  font-family: "Inter";
  font-weight: bold;
}
.Home .LivePriceDisplay .crypto .cryptoInfo .value {
  font-size: 16px;
  font-family: "Inter";
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
}
.Home .LivePriceDisplay .crypto .cryptoInfo .value i {
  font-size: 12px;
  border-radius: 50%;
  padding: 1px 2px 0px 2px;
  box-shadow: 1px 1px 2px rgb(33, 30, 30);
}
.Home .LivePriceDisplay .crypto img {
  width: 60px;
}

.loginSignup {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  margin-top: 50px;
  margin-bottom: 100px;
}
.loginSignup .loginSignupForm {
  position: relative;
  justify-self: center;
  background-color: white;
  width: 800px;
  height: 420px;
  border-radius: 32px;
  display: flex;
  box-shadow: 1px 1px 10px rgba(87, 87, 87, 0.5);
}
.loginSignup .loginSignupForm .welcomingMessage {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  width: 400px;
  left: 0;
  padding: 0px 40px 0px 40px;
  background: linear-gradient(-45deg, #2dc870, #02ad5d 30%);
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}
.loginSignup .loginSignupForm .welcomingMessage .bigLegend {
  font-family: sans-serif;
  align-self: center;
  font-weight: bold;
  font-size: 32px;
  margin-top: 40%;
  color: white;
  animation: welcomingText 0.8s forwards ease-in;
}
.loginSignup .loginSignupForm .welcomingMessage .shortGuide {
  align-self: center;
  font-family: "Inter";
  width: 300px;
  text-align: center;
  color: white;
  animation: welcomingText 1s forwards ease-in-out;
}
@keyframes welcomingText {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.loginSignup .loginSignupForm .welcomingMessage .welcomeBtn {
  align-self: center;
  padding: 10px 32px 12px 32px;
  border: 1px solid white;
  border-radius: 16px;
  color: white;
  font-family: "Inter";
  cursor: pointer;
  animation: welcomingBtn 1.2s ease-in-out;
}
@keyframes welcomingBtn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.loginSignup .loginSignupForm .welcomingMessage .welcomeBtn:hover {
  animation: alternativeLoginSignup 0.5s forwards ease-in;
}
@keyframes alternativeLoginSignup {
  100% {
    background-color: #13fd90;
    color: #066337;
  }
}
.loginSignup .loginSignupForm .codeOfVerifcationSec {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 0px 25px;
}
.loginSignup .loginSignupForm .codeOfVerifcationSec h2 {
  text-align: center;
  font-family: "Inter";
  font-weight: bold;
}
.loginSignup .loginSignupForm .codeOfVerifcationSec p {
  font-family: "Inter";
  text-align: center;
}
.loginSignup .loginSignupForm .codeOfVerifcationSec span {
  font-family: "Inter";
  padding: 0px 0px 10px 0px;
}
.loginSignup .loginSignupForm .codeOfVerifcationSec .error {
  color: red;
}
.loginSignup .loginSignupForm .codeOfVerifcationSec .options {
  font-family: "Inter";
  margin-top: 20px;
}
.loginSignup .loginSignupForm .codeOfVerifcationSec .options .option {
  color: #008a4a;
  cursor: pointer;
}
.loginSignup .loginSignupForm .signup {
  position: absolute;
  top: 16%;
  width: 250px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  animation: signup 1s forwards ease-in-out;
  transition: all 2s;
  padding: 0px 40px 0px 40px;
}
.loginSignup .loginSignupForm .signup .lastSetRegi {
  text-align: center;
  font-family: "Inter";
  color: #484848;
  font-size: 13px;
}
.loginSignup .loginSignupForm .signup .errors input {
  border: 1px solid red;
}
.loginSignup .loginSignupForm .signup .errors ::-moz-placeholder {
  color: #f33939 !important;
  opacity: 1;
}
.loginSignup .loginSignupForm .signup .errors ::placeholder {
  color: #f33939 !important;
  opacity: 1;
}
.loginSignup .loginSignupForm .signup h1 {
  font-family: sans-serif;
}
@keyframes signup {
  from {
    transform: translateY(40%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.loginSignup .loginSignupForm .login {
  position: absolute;
  top: 14%;
  width: 250px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  animation: login 1s forwards ease-in-out;
  transition: all 2s;
  padding: 0px 40px 0px 40px;
}
.loginSignup .loginSignupForm .login .lastSetRegi {
  text-align: center;
  font-family: "Inter";
  color: #484848;
  font-size: 13px;
}
.loginSignup .loginSignupForm .login .errors input {
  border: 1px solid red;
}
.loginSignup .loginSignupForm .login .errors ::-moz-placeholder {
  color: #f33939 !important;
  opacity: 1;
}
.loginSignup .loginSignupForm .login .errors ::placeholder {
  color: #f33939 !important;
  opacity: 1;
}
.loginSignup .loginSignupForm .login h1 {
  font-family: sans-serif;
}
@keyframes login {
  from {
    transform: translateY(40%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.loginSignup .loginSignupForm input {
  width: 200px;
  height: 45px;
  padding: 0px 0px 0px 20px;
  background-color: rgb(229, 229, 229);
  outline: none;
  color: black;
  border: none;
  border-radius: 10px;
}
.loginSignup .loginSignupForm input ::-webkit-input-placeholder {
  color: rgb(88, 88, 88);
  opacity: 1;
  font-size: 14px;
  font-family: "Inter";
}
.loginSignup .loginSignupForm input:focus {
  border: 2px solid rgb(105, 255, 130);
  border-radius: 10px;
}
.loginSignup .loginSignupForm button {
  margin-top: 8px;
  width: 150px;
  height: 40px;
  border-radius: 32px;
  outline: none;
  border: none;
  background-color: #02ad5d;
  font-family: "Inter";
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
.loginSignup .loginSignupForm button:hover {
  animation: contactus 0.5s forwards ease-in;
}
@keyframes contactus {
  100% {
    background-color: #02ad5d;
    color: #FFFFFF;
  }
}
.loginSignup .signupORlogin {
  width: 400px;
}

.ForgotPassword {
  width: 350px;
  text-align: center;
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  box-shadow: 1px 1px 10px rgba(104, 104, 104, 0.5);
  padding: 40px 40px 40px 40px;
  border-radius: 10px;
  font-family: "Inter";
  margin: 100px auto 0px auto;
  background-color: white;
  margin-bottom: 100px;
}
.ForgotPassword img {
  width: 60px;
}
.ForgotPassword h1 {
  margin-bottom: -5px;
  font-family: Arial, Helvetica, sans-serif;
}
.ForgotPassword p {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.ForgotPassword .emailSubm {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}
.ForgotPassword .emailSubm button, .ForgotPassword .emailSubm input {
  height: 40px;
  border-radius: 6px;
}
.ForgotPassword .emailSubm .err {
  color: red;
  border: 1px solid red;
}
.ForgotPassword .emailSubm input {
  border: 1px solid #bababa;
  padding: 0px 0px 0px 16px;
}
.ForgotPassword .emailSubm input:focus {
  outline: none;
}
.ForgotPassword .emailSubm button {
  background-color: #19bc2f;
  border: none;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.ForgotPassword .passwordInput {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
}
.ForgotPassword .passwordInput button, .ForgotPassword .passwordInput .emailSubm input, .ForgotPassword .emailSubm .passwordInput input {
  height: 40px;
  border-radius: 6px;
}
.ForgotPassword .passwordInput button {
  background-color: #19bc2f;
  border: none;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.ForgotPassword .passwordInput .inputFieldsCode {
  align-self: center;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.ForgotPassword .passwordInput .inputFieldsCode .inputField {
  width: 30px;
  height: 50px;
  padding: 5px 0px 0px 0px;
  font-size: 20px;
  text-align: center;
  border: 2px solid rgb(202, 202, 202);
  border-radius: 4px;
}
.ForgotPassword .passwordInput .inputFieldsCode input[type=number]::-webkit-outer-spin-button,
.ForgotPassword .passwordInput .inputFieldsCode input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ForgotPassword .passwordInput .inputFieldsCode ::-moz-placeholder {
  text-align: center;
  font-size: 20px;
}
.ForgotPassword .passwordInput .inputFieldsCode ::placeholder {
  text-align: center;
  font-size: 20px;
}
.ForgotPassword .error input {
  border: 1px solid red;
}
.ForgotPassword .error ::-moz-placeholder {
  color: red;
}
.ForgotPassword .error ::placeholder {
  color: red;
}
.ForgotPassword .backToLogin {
  display: block;
  margin-top: 30px;
  text-decoration: none;
  display: flex;
  -moz-column-gap: 6px;
       column-gap: 6px;
  align-items: center;
  color: rgb(86, 86, 86);
}
.ForgotPassword .backToLogin span {
  font-size: 14px;
}
.ForgotPassword .backToLogin i {
  font-size: 12px;
}

.AboutUs {
  display: flex;
  font-family: "Inter";
}
.AboutUs h1 {
  text-align: center;
}
.AboutUs p {
  font-size: 14px;
  text-align: center;
}
.AboutUs .shortCatch {
  text-align: center;
}
.AboutUs .Squares {
  position: relative;
  width: 1200px;
  height: 500px;
}
.AboutUs .Squares .SquaresWithInfo {
  background-color: white;
  position: absolute;
  padding: 10px 30px 10px 30px;
  border-radius: 10px;
  max-width: 300px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 10px rgba(51, 50, 50, 0.5);
  text-align: center;
}
.AboutUs .Squares .SquaresWithInfo img {
  width: 200px;
}
.AboutUs .Squares .SquaresWithInfo p {
  font-size: 14px;
  color: rgb(0, 0, 0);
}
.AboutUs .Squares .box0 {
  left: 100px;
}
.AboutUs .Squares .box1 {
  top: 200px;
}
.AboutUs .Squares .box2 {
  left: 20px;
}

.HowTo .mainTitle {
  text-align: center;
  font-family: "Inter";
}
.HowTo .middleSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: -moz-fit-content;
  width: fit-content;
  margin: 50px auto 50px auto;
}
.HowTo .middleSection .exchangeShortdescript {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 16px;
       column-gap: 16px;
  row-gap: 20px;
}
.HowTo .middleSection .exchangeShortdescript .boxInfo {
  padding: 10px 16px 0px 16px;
  width: 250px;
  box-shadow: 1px 1px 10px rgba(57, 57, 57, 0.5);
  border-radius: 26px;
  font-family: "Inter";
  cursor: pointer;
}
.HowTo .middleSection .exchangeShortdescript .boxInfo .upperPart {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.HowTo .middleSection .exchangeShortdescript .boxInfo .lowerPart {
  display: flex;
}
.HowTo .middleSection .exchangeShortdescript .boxInfo .lowerPart p {
  font-size: 13px;
}
.HowTo .middleSection .exchangeShortdescript .boxInfo .lowerPart i {
  display: none;
  font-size: 25px;
}
.HowTo .middleSection .exchangeShortdescript .boxInfo .tutorial {
  display: none;
}
.HowTo .middleSection .exchangeShortdescript .boxInfo:hover i {
  display: block;
  animation: arrowAnimation 0.5s forwards ease-out;
}
@keyframes arrowAnimation {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.HowTo .middleSection .DepoWithtutorial {
  width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  animation: depowithtutorial 0.5s forwards ease-in-out;
  font-family: "Inter";
  font-size: 13px;
  box-shadow: 1px 1px 10px rgba(39, 37, 37, 0.5);
  border-radius: 16px;
  padding: 10px 10px 20px 10px;
}
.HowTo .middleSection .DepoWithtutorial .upperPart {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
}
.HowTo .middleSection .DepoWithtutorial .lowerpart {
  padding: 0px 0px 0px 90px;
}
@keyframes depowithtutorial {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.personalArea {
  font-family: "Inter";
  display: grid;
  grid-template-columns: 16% auto 20%;
}
.personalArea .MainPart {
  height: 85vh;
  overflow-y: auto;
  border-radius: 10px;
}
.personalArea .MainPart .Orders {
  padding: 20px 0px 50px 30px;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 30px;
  height: -moz-fit-content;
  height: fit-content;
}
.personalArea .MainPart .CreateOrder {
  margin-top: 80px;
}
.personalArea .loader {
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
}
.personalArea .loader .lds-dual-ring-green {
  margin: 0px auto 0px auto;
}

.SignupSuccess {
  height: 80vh;
  display: flex;
  align-items: center;
  width: 100%;
}
.SignupSuccess .formSection {
  padding: 24px 24px 30px 24px;
  font-family: "Inter";
  min-height: 400px;
  margin: 0px auto 0px auto;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 1px 1px 10px rgb(104, 104, 104);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.SignupSuccess .formSection .upperPart {
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 24px 16px;
  border-bottom: 1px solid rgb(185, 185, 185);
}
.SignupSuccess .formSection .upperPart h1 {
  font-size: 24px;
  opacity: 0.5;
}
.SignupSuccess .formSection .upperPart img {
  width: 40px;
}
.SignupSuccess .formSection .middlePart {
  margin: 0px auto 0px auto;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
}
.SignupSuccess .formSection .middlePart p {
  align-self: center;
  max-width: 300px;
}
.SignupSuccess .formSection .middlePart .codeResent {
  color: #02ad5d;
}
.SignupSuccess .formSection .middlePart .errorCode {
  color: red;
}
.SignupSuccess .formSection .bottomPart {
  display: flex;
  -moz-column-gap: 50px;
       column-gap: 50px;
  align-self: center;
  margin: 20px 0px 0px 0px;
}
.SignupSuccess .formSection .bottomPart .link {
  text-decoration: none;
  color: #008a4a;
  cursor: pointer;
}

.Admin {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  height: 70vh;
}
.Admin .LeftSec {
  display: flex;
  flex-direction: column;
  row-gap: 34px;
  padding: 0px 0px 0px 24px;
}
.Admin .LeftSec .option {
  font-family: "Poppins";
  display: flex;
  align-items: center;
  -moz-column-gap: 17px;
       column-gap: 17px;
  cursor: pointer;
}
.Admin .LeftSec .option img {
  width: 20px;
}
.Admin .RightSec {
  background-color: #F8F8F8;
  padding: 20px 20px 20px 50px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
}
.Admin .RightSec .Userlist {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: white;
  font-family: "Inter", sans-serif;
  width: -moz-fit-content;
  width: fit-content;
}
.Admin .RightSec .Userlist th,
.Admin .RightSec .Userlist td {
  padding: 10px;
  border-bottom: 1px solid #d2d2d2;
  color: rgb(91, 91, 91);
}
.Admin .RightSec .Userlist thead th,
.Admin .RightSec .Userlist thead td {
  padding: 20px 10px 20px 10px;
  color: black;
}
.Admin .RightSec .Userlist .user {
  cursor: pointer;
  transition: all 0.5s;
}
.Admin .RightSec .Userlist .user .orderId {
  color: #0B63F8;
}
.Admin .RightSec .Userlist .user .creationTime {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.Admin .RightSec .Userlist .user:hover {
  background-color: #dfdfdf;
}
.Admin .RightSec .Userlist .OrderOption {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 0px 0px 10px 20px;
}
.Admin .RightSec .Userlist .OrderOption div {
  padding: 10px;
  box-shadow: 1px 1px 10px rgb(123, 123, 123);
  cursor: pointer;
  border-radius: 8px;
}
.Admin .RightSec .Userlist .OrderOption .orderCancelled {
  background-color: #ed0000;
  color: white;
}
.Admin .RightSec .Userlist .OrderOption .orderCompleted {
  background-color: #029e55;
  color: white;
}
.Admin .RightSec .SearchFields {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 40px;
       column-gap: 40px;
}
.Admin .RightSec .SearchFields label {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  font-family: "Poppins";
}
.Admin .RightSec .SearchFields label input {
  width: 246px;
  height: 50px;
  border: 2px solid rgba(91, 91, 91, 0.5);
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  padding: 0px 10px 0px 10px;
}
.Admin .RightSec .SearchFields label input[type=date] {
  font-family: "Poppins";
  color: rgba(22, 22, 22, 0.6);
  font-size: 16px;
}
.Admin .RightSec .DataWidget {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.Admin .RightSec .DataWidget .widget {
  height: 91px;
  width: 265px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgb(152, 152, 152);
  display: flex;
  align-items: center;
}
.Admin .RightSec .DataWidget .widget img {
  justify-self: end;
  width: 35px;
  margin: 0px 17.5px 0px 27px;
}
.Admin .RightSec .DataWidget .widget .TextInfo {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  margin: 0px auto 0px 0px;
}
.Admin .RightSec .DataWidget .widget .TextInfo .sum {
  font-size: 22px;
  font-weight: bold;
}
.Admin .RightSec .MessageSec {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.Admin .RightSec .MessageSec .Messages {
  display: grid;
  -moz-column-gap: 20px;
       column-gap: 20px;
  grid-template-columns: auto 50%;
  font-family: "Inter";
}
.Admin .RightSec .MessageSec .Messages .messageUser {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.Admin .RightSec .MessageSec .Messages .messageUser .user {
  box-shadow: 1px 1px 10px rgb(44, 44, 44);
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.Admin .RightSec .MessageSec .Messages .messageUser .user .dateOfCreation {
  justify-self: end;
}
.Admin .RightSec .MessageSec .Messages .messageUser .active {
  box-shadow: none;
}
.Admin .RightSec .MessageSec .Messages .chatSec {
  max-height: 60vh;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px rgb(44, 44, 44);
}
.Admin .RightSec .MessageSec .Messages .chatSec .userId {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.Admin .RightSec .MessageSec .Messages .chatSec .userId .icon {
  border: 1px solid #d2d2d2;
  width: 34px;
  text-align: center;
  padding: 8px 0px 8px 0px;
  border-radius: 50%;
}
.Admin .RightSec .MessageSec .Messages .chatSec .userId input {
  font-family: "Poppins";
  width: 200px;
  height: 30px;
  border: 2px solid rgba(91, 91, 91, 0.5);
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  padding: 0px 10px 0px 10px;
  justify-self: end;
}
.Admin .RightSec .MessageSec .Messages .chatSec .userId input[type=date] {
  font-family: "Poppins";
  color: rgba(22, 22, 22, 0.6);
  font-size: 16px;
}
.Admin .RightSec .MessageSec .Messages .chatSec .userId button {
  height: 34px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 5px black;
}
.Admin .RightSec .MessageSec .Messages .chatSec .userId button:focus {
  animation: shadow 0.3s ease-in-out;
  /* Add animation */
}
@keyframes shadow {
  0% {
    box-shadow: 0px 0px 8px rgb(0, 0, 0);
    /* Initial box shadow */
  }
  50% {
    box-shadow: 0px 0px 0px rgb(0, 0, 0), 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Remove box shadow and add shadow below */
  }
  100% {
    box-shadow: 0px 0px 8px rgb(0, 0, 0);
    /* Final box shadow */
  }
}
.Admin .RightSec .MessageSec .Messages .chatSec .chatmessages {
  background-color: #dfdfdf;
  border-radius: 10px;
  min-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.Admin .RightSec .MessageSec .Messages .chatSec .chatmessages .admin {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.Admin .RightSec .MessageSec .Messages .chatSec .chatmessages .admin .icon {
  background-color: white;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 34px;
  padding: 5px;
  border-radius: 50%;
  height: -moz-fit-content;
  height: fit-content;
}
.Admin .RightSec .MessageSec .Messages .chatSec .chatmessages .admin p {
  background-color: white;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
}
.Admin .RightSec .MessageSec .Messages .chatSec .chatmessages .user {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-self: flex-end;
}
.Admin .RightSec .MessageSec .Messages .chatSec .chatmessages .user .icon {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 5px;
}
.Admin .RightSec .MessageSec .Messages .chatSec .chatmessages .user .icon i {
  margin: 0px auto 0px auto;
}
.Admin .RightSec .MessageSec .Messages .chatSec .chatmessages .user p {
  background-color: white;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
}
.Admin .RightSec .MessageSec .Messages .chatSec .sendingMessage {
  width: 100%;
  display: grid;
  grid-template-columns: 90% auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.Admin .RightSec .MessageSec .Messages .chatSec .sendingMessage button, .Admin .RightSec .MessageSec .Messages .chatSec .sendingMessage input {
  border-radius: 10px;
  outline: none;
  border: 1px solid #d2d2d2;
}
.Admin .RightSec .MessageSec .Messages .chatSec .sendingMessage button {
  width: 50px;
  height: 40px;
  background-color: #029e55;
}
.Admin .RightSec .MessageSec .Messages .chatSec .sendingMessage button i {
  color: rgb(255, 255, 255);
}
.Admin .RightSec .SearchMessage {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 40px;
       column-gap: 40px;
}
.Admin .RightSec .SearchMessage label {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  font-family: "Poppins";
}
.Admin .RightSec .SearchMessage label input {
  width: 246px;
  height: 50px;
  border: 2px solid rgba(91, 91, 91, 0.5);
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  padding: 0px 10px 0px 10px;
}
.Admin .RightSec .SearchMessage label input[type=date] {
  font-family: "Poppins";
  color: rgba(22, 22, 22, 0.6);
  font-size: 16px;
}

.adminLoginSec .codeVerify {
  margin: 100px auto 0px auto;
  height: 400px;
  box-shadow: 1px 1px 10px rgba(15, 15, 15, 0.5);
  width: 400px;
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  padding: 20px 10px 0px 10px;
  border-radius: 10px;
}
.adminLoginSec .codeVerify .title {
  text-align: center;
}
.adminLoginSec .codeVerify img {
  width: 100px;
}
.adminLoginSec .codeVerify .resendCode {
  color: #02ad5d;
  cursor: pointer;
}
.adminLoginSec .adminLogin {
  height: 323px;
  width: 463px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgb(83, 83, 83);
  margin: 100px auto 100px auto;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  padding: 44px 88px 44px 88px;
}
.adminLoginSec .adminLogin .title {
  align-self: center;
  font-family: "Inter";
  color: #666666;
}
.adminLoginSec .adminLogin label {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-family: "Inter";
  color: #666666;
}
.adminLoginSec .adminLogin label .error {
  border: 1px solid red !important;
}
.adminLoginSec .adminLogin label input {
  height: 38px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #dfdfdf;
  padding-left: 20px;
}
.adminLoginSec .adminLogin button {
  background-color: #016e3b;
  border: none;
  cursor: pointer;
  padding: 13px;
  border-radius: 10px;
  color: white;
  font-family: "Inter";
  font-size: 16px;
}
.adminLoginSec .adminLogin .forgotPass {
  align-self: flex-end;
  font-family: "Inter";
  color: #999999;
}

.AdminLayout header {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 2px 0px 8px 0px;
  display: grid;
  grid-template-columns: auto auto;
  -moz-column-gap: 450px;
       column-gap: 450px;
}
.AdminLayout header h1 {
  font-family: "Inter";
  justify-self: center;
}
.AdminLayout header h1 span {
  color: #0af185;
}
.AdminLayout header .links {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  justify-self: center;
}
.AdminLayout header .links .link {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-decoration: none;
  color: white;
  background-color: #008a4a;
  padding: 10px;
  border-radius: 10px;
  font-family: "Inter";
  align-self: center;
}/*# sourceMappingURL=index.css.map */