.filters {
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: fit-content;

    .title {
        align-self: start;
        font-weight: bold;
    }

    .Date {
        align-self: center;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .dateSec {
            width: fit-content;

            display: flex;
            column-gap: 20px;
            flex-wrap: wrap;

            label {
                display: flex;
                flex-direction: column;
                row-gap: 6px;

                span {
                    font-size: 13px;
                    font-weight: bold;
                }
            }

            input[type="date"] {
                width: 105px;
                border-radius: 10px;
                font-family: 'Inter';
                font-size: 12px;
                border: 2px solid #D1D1D1;
                outline: none;
                padding: 10px 8px 10px 8px;
                cursor: pointer;
            }

        }
    }

    .AscDescOrder {
        align-self: center;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .AscDescSec {
            display: flex;
            column-gap: 20px;
            align-self: center;
            flex-wrap: wrap;
            width: fit-content;

            .checkSquare {
                color: #008a4a;
                font-size: 25px;
            }

            %commonDesign {
                border: 1px solid #D1D1D1;
                background-color: #F9F9F9;
                padding: 12px 10px 12px 10px;
                width: fit-content;
                border-radius: 8px;
                display: flex;
                align-items: center;
                column-gap: 10px;
                cursor: pointer;

                .square {
                    height: 20px;
                    width: 20px;
                    border: 2px solid #D1D1D1;
                    border-radius: 6px;
                }
            }

            #Asc {
                @extend %commonDesign;
            }

            #Desc {
                @extend %commonDesign;
            }
        }
    }

    .Currency {
        align-self: center;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .title {
            align-self: center;
        }

        .currency {
            list-style: none;

            li {
                display: flex;
                column-gap: 10px;
                align-items: center;
                cursor: pointer;

                .square {
                    height: 20px;
                    width: 20px;
                    border: 2px solid #D1D1D1;
                    border-radius: 6px;
                }

                .checkSquare {
                    color: #008a4a;
                    font-size: 25px;
                }
            }

            list-style: none;
            line-height: 30px;

        }
    }

    .PriceRange {
        align-self: center;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .Range {
            display: flex;
            column-gap: 20px;

            label {
                display: flex;
                flex-direction: column;

                span {
                    font-weight: bold;
                    font-size: 13px;
                }
            }

            input {
                border-radius: 12px;
                width: 80px;
                height: 35px;
                border: 2px solid #D1D1D1;
                outline: none;
                background-color: #f0f0f0;
                padding: 0px 0px 0px 8px;

            }
        }
    }


    .resetBtn {
        padding: 10px 16px 10px 16px;
        width: fit-content;
        align-self: center;
        border-radius: 10px;
        border: 1px solid #008a4a;
        outline: none;
        color: white;
        font-family: 'Inter';
        background-color: #02a75a;
        font-weight: bold;
        cursor: pointer;
    }

    .resetBtn:hover {
        animation: resetBtn 0.5s forwards ease-in-out;
    }

    @keyframes resetBtn {
        to {
            color: #006435;
            background-color: #13fd90;
            border-color: #13fd90;
        }
    }
}