.MessageSec .message {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-left: 50px;
}
.MessageSec .message .date {
  background-color: #D1D1D1;
  padding: 12px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px;
}
.MessageSec .message .notification {
  padding: 0px 0px 0px 15px;
  width: 80%;
  height: 50px;
  box-shadow: 1px 1px 10px rgba(73, 73, 73, 0.5);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}
.MessageSec .message .notification .leftSide {
  display: flex;
  -moz-column-gap: 8px;
       column-gap: 8px;
  align-items: center;
}
.MessageSec .message .notification .leftSide img {
  border-radius: 50%;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 1px 1px 10px rgb(113, 113, 113);
}
.MessageSec .message .notification .leftSide div {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
}
.MessageSec .message .notification .leftSide div .description {
  color: #6e6e6e;
}
.MessageSec .message .notification .time {
  justify-self: end;
  margin-right: 20px;
}
@keyframes notificationSlide {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}/*# sourceMappingURL=MessagesSec.css.map */