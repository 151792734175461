.LeftSideMenu {
  height: inherit;
  min-height: 80vh;
  display: grid;
  grid-template-rows: auto auto;
  padding-left: 30px;
  background-color: white;
}
.LeftSideMenu .upperBtnSec {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: -moz-fit-content;
  width: fit-content;
}
.LeftSideMenu .upperBtnSec .btnLeftMenu {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 10px;
  border: 2px solid transparent;
  cursor: pointer;
}
.LeftSideMenu .upperBtnSec .btnLeftMenu img {
  max-width: 30px;
}
.LeftSideMenu .upperBtnSec .btnLeftMenu:hover {
  border: 2px solid #02ad5d;
  border-radius: 10px;
}
.LeftSideMenu .leftMenuLogout {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 10px;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  border: 2px solid transparent;
}
.LeftSideMenu .leftMenuLogout img {
  max-width: 30px;
}
.LeftSideMenu .leftMenuLogout:hover {
  border: 2px solid #02ada7;
  border-radius: 10px;
}/*# sourceMappingURL=LeftSideMenu.css.map */