.MessageSec {
    .message {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-left: 50px;

        .date {
            background-color: #D1D1D1;
            padding: 12px;
            width: fit-content;
            margin-top: 20px;
        }

        .notification {
            padding: 0px 0px 0px 15px;
            width: 80%;
            height: 50px;
            box-shadow: 1px 1px 10px rgb(73, 73, 73, 0.5);
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;

            .leftSide {
                display: flex;
                column-gap: 8px;
                align-items: center;

                img {
                    border-radius: 50%;
                    height: fit-content;
                    box-shadow: 1px 1px 10px rgb(113, 113, 113);
                }

                div {
                    display: flex;
                    flex-direction: column;

                    font-family: 'Inter';

                    .description {
                        color: #6e6e6e;
                    }
                }

            }

            .time {
                justify-self: end;
                margin-right: 20px;
            }
        }

        @keyframes notificationSlide {
            from {
                opacity: 0;
                transform: translateX(-20%);
            }

            to {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }


}