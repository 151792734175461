.Order {
    box-shadow: 1px 1px 10px rgb(109, 109, 109);
    background-color: white;
    border-radius: 16px;
    min-width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 10px;


    .upperPart {
        display: grid;
        grid-template-columns: auto auto;
        width: 90%;
        padding: 10px 10px 10px 10px;

        .text {
            display: flex;
            flex-direction: column;
            justify-self: center;

            .id {
                font-weight: bold;
                width: 80%;
            }

            .dateTime {
                color: #797B7E;
                font-size: 12px
            }

        }

        img {
            justify-self: center;
            width: 35px;
        }
    }

    .middlePart {
        display: grid;
        grid-template-columns: auto auto auto;
        width: 80%;
        margin: 0px auto 0px auto;
        align-items: center;

        .Name {
            font-size: 16px;
            font-weight: lighter;
        }

        .cryptoVal {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .usdVal {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    button {
        width: 100%;
        height: 40px;
        border-bottom-right-radius: inherit;
        border-bottom-left-radius: inherit;
        outline: none;
        cursor: pointer;
        border: 2px solid red;
        color: red;
        background-color: transparent;
        font-weight: bold;
        font-family: 'Inter';
    }

    button:hover {
        background-color: red;
        color: white;
    }

}

@keyframes orderDisp {
    from {
        opacity: 0;
        transform: translateY(-50%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}