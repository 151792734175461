@import url("https://fonts.googleapis.com/css?family=Exo");
@import url("https://fonts.googleapis.com/css?family=Inter");
.CreateOrder {
  font-family: "Inter";
  position: relative;
  margin: 0px auto 0px auto;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 1px 1px 10px rgba(37, 37, 37, 0.8);
  padding: 60px 15px 10px 15px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  height: -moz-fit-content;
  height: fit-content;
  width: 500px;
}
.CreateOrder .sell, .CreateOrder .buy {
  position: absolute;
  top: 0;
  padding: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: white;
}
.CreateOrder .buy {
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  left: 0;
  box-shadow: 2px 2px 7px rgba(27, 26, 26, 0.6);
}
.CreateOrder .sell {
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  right: 0;
  background-color: #3a3a3a;
  box-shadow: -2px 2px 7px rgba(27, 26, 26, 0.6);
}
.CreateOrder .Email #email, .CreateOrder .addressFields #addressField {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #D1D1D1;
}
.CreateOrder #network .options, .CreateOrder #asset .options {
  display: none;
  position: absolute;
  background-color: white;
  width: 500px;
  box-shadow: 1px 2px 10px rgb(65, 65, 65);
  list-style: none;
  z-index: 1;
}
.CreateOrder #network .options div, .CreateOrder #asset .options div {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  padding: 8px 0px 8px 16px;
  cursor: pointer;
}
.CreateOrder #network .options div:hover, .CreateOrder #asset .options div:hover {
  background-color: #dcdcdc;
}
.CreateOrder #asset {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
}
.CreateOrder #asset .selectAsset {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #D1D1D1;
  display: grid;
  grid-template-columns: auto auto;
}
.CreateOrder #asset .selectAsset .leftSide {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.CreateOrder #asset .selectAsset img {
  justify-self: end;
  align-self: center;
}
.CreateOrder #network {
  cursor: pointer;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
}
.CreateOrder #network .selectedNetwork {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #D1D1D1;
  display: grid;
  grid-template-columns: auto auto;
}
.CreateOrder #network .selectedNetwork span {
  justify-self: start;
}
.CreateOrder #network .selectedNetwork img {
  justify-self: end;
}
.CreateOrder .addressFields {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  font-size: 14px;
}
.CreateOrder .addressFields #addressField {
  background-color: #efefef;
  outline: none;
  border: 1px solid #9b9a9a;
}
.CreateOrder .transacAmount {
  -moz-column-gap: 10px;
       column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
}
.CreateOrder .transacAmount .fiat, .CreateOrder .transacAmount .currentRate, .CreateOrder .transacAmount .cryptoEqui {
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 100px;
}
.CreateOrder .transacAmount .cryptoEqui .cryptValue {
  height: inherit;
  border-radius: 10px;
  outline: none;
  border: 1px solid #D1D1D1;
  padding: 5px 0px 0px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
.CreateOrder .transacAmount .cryptoEqui .cryptValue::-webkit-scrollbar {
  height: 6px;
}
.CreateOrder .transacAmount .cryptoEqui .cryptValue::-webkit-scrollbar-thumb {
  border-radius: inherit;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.CreateOrder .transacAmount .cryptoEqui .labelCrypt {
  font-size: 13px;
}
.CreateOrder .transacAmount .currentRate .labelFiat {
  align-self: center;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  font-size: 13px;
}
.CreateOrder .transacAmount .currentRate .fiatValue {
  align-self: center;
}
.CreateOrder .transacAmount .fiat {
  font-size: 12px;
}
.CreateOrder .transacAmount .fiat #currencyField {
  height: inherit;
  border-radius: 10px;
  outline: none;
  border: 1px solid #D1D1D1;
  padding: 0px 0px 0px 10px;
}
.CreateOrder .Email {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.CreateOrder .Email #email {
  outline: none;
  background-color: #efefef;
}
.CreateOrder .errors {
  border: 1px solid red !important;
}
.CreateOrder .errors::-moz-placeholder {
  color: red;
}
.CreateOrder .errors::placeholder {
  color: red;
}
.CreateOrder .confirmBtn {
  font-family: "Inter";
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.2ch;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: 1px 1px 10px rgba(28, 28, 28, 0.5);
  cursor: pointer;
}/*# sourceMappingURL=CreateOrder.css.map */