.verificationCodeComp {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  width: 240px;
}
.verificationCodeComp button {
  width: 100%;
  height: 40px;
  cursor: pointer;
  background-color: #008a4a;
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  font-size: 16px;
}
.verificationCodeComp .inputFieldsCode {
  align-self: center;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.verificationCodeComp .inputFieldsCode .inputField {
  width: 30px;
  height: 50px;
  padding: 5px 0px 0px 0px;
  font-size: 20px;
  text-align: center;
  border: 2px solid rgb(202, 202, 202);
  border-radius: 4px;
}
.verificationCodeComp .inputFieldsCode input[type=number]::-webkit-outer-spin-button,
.verificationCodeComp .inputFieldsCode input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.verificationCodeComp .inputFieldsCode ::-moz-placeholder {
  text-align: center;
  font-size: 20px;
}
.verificationCodeComp .inputFieldsCode ::placeholder {
  text-align: center;
  font-size: 20px;
}/*# sourceMappingURL=verificationCode.css.map */