.LeftSideMenu {
    height: inherit;
    min-height: 80vh;
    display: grid;
    grid-template-rows: auto auto;
    padding-left: 30px;
    background-color: white;

    .upperBtnSec {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: fit-content;

        .btnLeftMenu {
            display: flex;
            align-items: center;
            column-gap: 10px;
            padding: 10px;
            border: 2px solid transparent;
            cursor: pointer;

            img {
                max-width: 30px;
            }
        }

        .btnLeftMenu:hover {
            border: 2px solid #02ad5d;
            border-radius: 10px;
        }
    }

    .leftMenuLogout {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 10px;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        align-self: center;
        border: 2px solid transparent;

        img {
            max-width: 30px;
        }

    }

    .leftMenuLogout:hover {
        border: 2px solid #02ada7;
        border-radius: 10px;
    }
}