.verificationCodeComp {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    width: 240px;
    button {
        width: 100%;
        height: 40px;
        cursor: pointer;
        background-color: #008a4a;
        border: none;
        outline: none;
        color: white;
        font-weight: bold;
        border-radius: 6px;
        font-size: 16px;
    }

    .inputFieldsCode {
        align-self: center;
        display: flex;
        column-gap: 10px;

        .inputField {
            width: 30px;
            height: 50px;
            padding: 5px 0px 0px 0px;
            font-size: 20px;
            text-align: center;
            border: 2px solid rgb(202, 202, 202);
            border-radius: 4px;
        }

        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        ::placeholder {
            text-align: center;
            font-size: 20px;
        }
    }
}