%commonLoadingBtn {
    display: inline-block;
    width: fit-content;
    height: fit-content;
    align-self: center;
}

%commonLoadingBtnAfter {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring-green {
    @extend %commonLoadingBtn;
}

.lds-dual-ring-green:after {
    @extend %commonLoadingBtnAfter;
    border: 6px solid #19bc2f;
    border-color: #19bc2f transparent #19bc2f transparent;
}

.lds-dual-ring-red {
    @extend %commonLoadingBtn;
}

.lds-dual-ring-red:after {
    @extend %commonLoadingBtnAfter;
    border: 6px solid #ff0000;
    border-color: #ff0000 transparent #ff0000 transparent;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes confirmBuySell {
    100% {
        box-shadow: 0px 0px 8px rgb(0, 0, 0);
    }
}

.wrapper {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

%checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark__circle_green {
    @extend %checkmark__circle;
    stroke: #19bc2f;
}

.checkmark__circle_red {
    @extend %checkmark__circle;
    stroke: #ff0000;
}

%checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
}

.checkmark-green {
    @extend %checkmark;
    box-shadow: inset 0px 0px 0px #19bc2f;
    animation: fillgreen .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark-red {
    @extend %checkmark;
    box-shadow: inset 0px 0px 0px #ff0000;
    animation: fillred .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fillgreen {
    100% {
        box-shadow: inset 0px 0px 0px 30px #19bc2f
    }
}

@keyframes fillred {
    100% {
        box-shadow: inset 0px 0px 0px 30px #ff0000
    }
}