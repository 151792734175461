@import url('https://fonts.googleapis.com/css?family=Exo');
@import url('https://fonts.googleapis.com/css?family=Inter');

.CreateOrder {
    font-family: 'Inter';
    position: relative;
    margin: 0px auto 0px auto;
    width: fit-content;
    box-shadow: 1px 1px 10px rgba(37, 37, 37, 0.8);
    padding: 60px 15px 10px 15px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    height: fit-content;
    width: 500px;
    

    %upperBtnCommonDes {
        position: absolute;
        top: 0;
        padding: 14px;
        outline: none;
        border: none;
        cursor: pointer;
        font-weight: bold;
        color: white;
    }

    .buy {
        @extend %upperBtnCommonDes;
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;
        left: 0;
        box-shadow: 2px 2px 7px rgb(27, 26, 26, 0.6);
    }

    .sell {
        @extend %upperBtnCommonDes;
        border-top-right-radius: 16px;
        border-bottom-left-radius: 16px;
        right: 0;
        background-color: #3a3a3a;
        box-shadow: -2px 2px 7px rgb(27, 26, 26, 0.6);
    }

    %commonDesing {
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #D1D1D1;
    }

    %optionDesign {
        display: none;
        position: absolute;
        background-color: white;
        width: 500px;
        box-shadow: 1px 2px 10px rgb(65, 65, 65);
        list-style: none;
        z-index: 1;

        div {
            display: flex;
            align-items: center;
            column-gap: 8px;
            padding: 8px 0px 8px 16px;
            cursor: pointer;
        }

        div:hover {
            background-color: #dcdcdc;
        }
    }

    #asset {
        width: 100%;
        border: none;
        background-color: transparent;
        font-size: 14px;
        .options {
            @extend %optionDesign;

        }

        .selectAsset {
            cursor: pointer;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #D1D1D1;
            display: grid;
            grid-template-columns: auto auto;

            .leftSide {
                display: flex;
                align-items: center;
                column-gap: 10px;
            }

            img {
                justify-self: end;
                align-self: center;
            }
        }
    }

    #network {
        cursor: pointer;
        width: 100%;
        border: none;
        background-color: transparent;
        font-size: 14px;
        .selectedNetwork {
            cursor: pointer;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #D1D1D1;
            display: grid;
            grid-template-columns: auto auto;

            span {
                justify-self: start;
            }

            img {
                justify-self: end;
            }
        }

        .options {
            @extend %optionDesign;

        }

    }

    .addressFields {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        font-size: 14px;
        #addressField {
            @extend %commonDesing;
            background-color: #efefef;
            outline: none;
            border: 1px solid #9b9a9a;
        }
    }


    .transacAmount {

        column-gap: 10px;
        display: grid;
        grid-template-columns: auto auto auto;
        justify-items: center;

        %commonDesign {
            display: flex;
            flex-direction: column;
            height: 50px;
            width: 100px;
            
        }
       
        .cryptoEqui {
            @extend %commonDesign;


            .cryptValue {
                height: inherit;
                border-radius: 10px;
                outline: none;
                border: 1px solid #D1D1D1;
                padding: 5px 0px 0px 10px;
                overflow-x: auto;
                overflow-y: hidden;

            }

            .cryptValue::-webkit-scrollbar {
                height: 6px;
            }
            .cryptValue::-webkit-scrollbar-thumb {
                border-radius: inherit;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: #555;
            }
            .labelCrypt{
                font-size: 13px
            }
        }

        .currentRate {
            @extend %commonDesign;

            .labelFiat {
                align-self: center;
                display: flex;
                align-items: center;
                column-gap: 5px;
                font-size: 13px;
            }

            .fiatValue {
                align-self: center;
            }
        }

        .fiat {
            @extend %commonDesign;
            font-size: 12px;
            #currencyField {
                height: inherit;
                border-radius: 10px;
                outline: none;
                border: 1px solid #D1D1D1;
                padding: 0px 0px 0px 10px;
            }
        }
    }

    .Email {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        #email {
            @extend %commonDesing;
            outline: none;
            background-color: #efefef;
        }
    }
    .errors{
        border: 1px solid red !important;
    }
    .errors::placeholder{
        color: red;
    }

    .confirmBtn {
        font-family: 'Inter';
        height: 40px;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.2ch;
        border-radius: 10px;
        outline: none;
        border: none;
        box-shadow: 1px 1px 10px rgb(28, 28, 28, 0.5);
        cursor: pointer;
    }
}